import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage,deleteToken } from "firebase/messaging";
import { firebaseConfigServer,vapKeyServer } from "./common/utility/constant";

// Initialize Firebase
const app = initializeApp(firebaseConfigServer);
const messaging = getMessaging(app);

// Function to request notification permission
export const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");
      const token = await getToken(messaging, { vapidKey: vapKeyServer });
      console.log("FCM Token:", token);
      return token;
    } else {
      console.log("Notification permission denied.");
      return null;
    }
  } catch (error) {
    console.error("⚠️ Error getting permission:", error);
    return null;
  }
};


export const deregisterFCMToken = async () => {
  try {
    const messaging = getMessaging();
    const currentToken = await getToken(messaging, { vapidKey: vapKeyServer });

    if (currentToken) {
      await deleteToken(messaging);
      console.log('FCM token deleted successfully');
      // Optionally, notify your backend that the token was removed
    } else {
      console.log('No FCM token found');
    }
  } catch (error) {
    console.error('Error deleting FCM token:', error);
  }
};

// Foreground message handler
onMessage(messaging, (payload) => {
    console.log("FCM Foreground Message:", payload);
    
    // Show notification manually. We can change it to other UI components as per the requirement here
    new Notification(payload.data.title, {
      body: payload.data.body,
      icon: "/favicon.ico",
    });
  });

export { messaging };
