import React,{memo, useContext,useEffect,useState,useRef} from 'react'
import { UserSettingsContext } from '../../../setup/routes-manager/RequireAuth'
import { useAxiosWithAuth } from '../../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../../common/api/urls';
import { useNavigate, useLocation } from 'react-router-dom';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION,TABLE_TYPES,OF_ZOOM_TYPE, USER_ROLE_FIELDS } from '../../../common/utility/constant';
import { Snackbar,Alert,CircularProgress,InputLabel,Switch,Radio,RadioGroup,FormControlLabel,FormLabel, Link ,Checkbox,useTheme} from '@mui/material';
import {Grid, Box,Typography,Select as MUISelect,ListSubheader,TextField,InputAdornment,FormControl,MenuItem} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SettingsFooter from './settings_footer';
import { findIndex } from '@amcharts/amcharts5/.internal/core/util/Array';
import { VP_TYPE,VA_TYPE } from '../../../common/utility/mp_chart_constants';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { createDictList, transformAndReverseMap } from '../../../common/utility/helperFunc';
import Modal from '@mui/material/Modal';

import { List } from 'react-virtualized';
import 'react-virtualized/styles.css'; // Import the styles for the List component
import useMediaQuery from '@mui/material/useMediaQuery';
import SettingsFooterPopup from './settings_footer_popup';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Select,ConfigProvider } from 'antd';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useAuth from '../../../common/hooks/useAuth';
import { deregisterFCMToken, requestNotificationPermission } from '../../../firebase';


const { Option } = Select;


const style = {
    position: 'absolute',
    top: '5%',
    left: '10%',
    height: "90%",
    //   transform: 'translate(-50%, -50%)',
    width: "80%",
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 0,
    overflow: 'scroll',
  };

const containsText = (text, searchText) =>{
  return text.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
}


 const ITEM_HEIGHT = 26;
 const ITEM_PADDING_TOP = 8;
 const MenuProps = {
   PaperProps: {
     style: {
       maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
       width: 80,
       autoFocus: false
     },
   },
 };

 const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));
const MPSettings = (props) => {
  const from = "/login";
  const theme=useTheme();
  const { auth } = useAuth();
  const [isPro2User,setIsPro2User]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.PRO2))?true:false)
  const [modalOpen, setModalOpen] = React.useState(props.open);
  const navigate = useNavigate(); 
  const [searchText, setSearchText] = React.useState("");
  const inputRef = React.useRef();
  const [options,setOptions]=useState();
  const { userSettings, setUserSettings,UIData } = useContext(UserSettingsContext);
  const [selectedInstrument, setSelectedInstrument] = React.useState(userSettings.data.default_instrument);
  const [showGlobalVolumeProfile,setShowGlobalVolumeProfile ] =useState(userSettings.data.show_global_vp);
  const [showAllVolumeProfile,setShowAllVolumeProfile ] =useState(userSettings.data.show_all_vp);
  const [showGlobalVolumeNumberProfile,setShowGlobalVolumeNumberProfile ] =useState(userSettings.data.show_global_vp_numbers);
  const [showAllVolumeNumber,setShowAllVolumeNumber ] =useState(userSettings.data.show_profile_vp_numbers);
  const [showOISpikes,setShowOISpikes ] =useState(userSettings.data.show_split_profile_oi_spikes!=undefined?userSettings.data.show_split_profile_oi_spikes:true);
  const [showTpoVAMarkers,setShowTpoVAMarkers ] =useState(userSettings.data.show_split_profile_va_markers!=undefined?userSettings.data.show_split_profile_va_markers:true);
  const [showUiNpocKrl,setShowUiNpocKrl ] =useState(userSettings.data.show_ui_npoc_krl!=undefined?userSettings.data.show_ui_npoc_krl:true);
  const [showKrl,setShowKrl ] =useState(userSettings.data.show_krl!=undefined?userSettings.data.show_krl:false);
  const [showCustomKrl,setShowCustomKrl ] =useState(userSettings.data.custom_krl_display!=undefined?userSettings.data.custom_krl_display:false);
  const [customKrlMultipleAnchor,setCustomKrlMultipleAnchor ] =useState(userSettings.data.custom_krl_overlapping_anchoring!=undefined?userSettings.data.custom_krl_overlapping_anchoring:false);
  const [anchorVwap2sdDisplay,setAnchorVwap2sdDisplay ] =useState(userSettings.data.show_2sd_band_custom_anchored_krl!=undefined?userSettings.data.show_2sd_band_custom_anchored_krl:false);
  const [showOFNPOC,setShowOFNPOC ] =useState(userSettings.data.show_ui_npoc_krl_of!=undefined?userSettings.data.show_ui_npoc_krl_of:true);
  const [showOFMetadata,setShowOFMetadata ] =useState(userSettings.data.show_metadata_marker_of!=undefined?userSettings.data.show_metadata_marker_of:true);
  const [vpType,setVPType]=useState(userSettings.data.vp_type)
  const [vaType,setVAType]=useState(userSettings.data.va_type)
  const [maxCompositeDays,setMaxCompositeDays]=useState(userSettings.data.max_ndays_for_composite)
  const [defaultLineChartNDays,setDefaultLineChartNDays]=useState(userSettings.data.default_lc_ndays)
  const [autosaveInterval,setAutosaveInterval]=useState(userSettings.data.autosave_interval)
  const [numberFormatLocale,setNumberFormatLocale]=useState(userSettings.data.number_format_locale)
  const [dataTypeContracts,setDataTypeContracts]=useState(userSettings.data.data_in_contracts);
  const [ordeflowZoomType,setOrdeflowZoomType]=useState(userSettings.data.of_zoom_type?userSettings.data.of_zoom_type:OF_ZOOM_TYPE.ASYMMETRIC)
  const [browserPushNotification, setBrowserPushNotification]=useState(userSettings.data.browser_push_notifications!=undefined?userSettings.data.browser_push_notifications:false);
  const [isNotificationChanged,setNotificationChanged]=useState(false);
  const selectRef = useRef();
  
  const autoFocus=()=>{
    const timeout = setTimeout(() => {
      if( inputRef.current)
      inputRef.current.focus();

    }, 100);

  }

  console.log(userSettings.data)

  const [uiDataState,setUIDataState]=useState({
    tpoGroupData:undefined,
    instrumentData:undefined,
    tpoGroups:undefined,
    isTPOLoaded:false,
    tableData:undefined
  })
  const {tpoGroupData,instrumentData,isTPOLoaded,tpoGroups,tableData}=uiDataState;

  const [settingsState,setSettingsState]=useState({
    settingsData:userSettings,
    isUserSettingsLoaded:userSettings!=undefined && userSettings.data!=undefined,
    apiTableData:userSettings.data.page_wise_hidden_columns  
  })
  const {settingsData,isUserSettingsLoaded,apiTableData}=settingsState;
  const [currentSettings,setCurrentSettings]=useState()
  
  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });
  
  const { open, msg, severity } = msgState;

  useEffect(() => {
    // document.title = "User Settings";
    console.log("MP settings props instrument and data=",props.instrument,props.selectedInstrumentData)
  }, []);
  
  const handleModalClose = () => {
    // setOpen(false);
    props.cancelHandler();
  };
  
  const [cancel,responseData, error, loaded, reset,executeAPI ] = useAxiosWithAuth();
  const [cancelSettings,responseDataSettings, errorSettings, loadedSettings, resetSettings,executeAPISettings ] = useAxiosWithAuth();
  const [cancelDefault,responseDataDefault, errorDefault, loadedDefault, resetDefault,executeAPIDefault ] = useAxiosWithAuth();
  const [cancelToken,responseDataToken, errorToken, loadedToken, resetToken,executeAPIToken ] = useAxiosWithAuth();

  const parentRef = useRef(null);
  const [parentWidth, setParentWidth] = useState(null);
  
  useEffect(() => {
    // Function to update parentWidth
    const updateParentWidth = () => {
      if (parentRef.current) {
        const width = parentRef.current.offsetWidth;
        console.log("parent width useeffect=",width)
        setParentWidth(width);
      }
    };

    // Initial update
    updateParentWidth();

    // Attach event listener for window resize
    window.addEventListener('resize', updateParentWidth);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, [parentRef.current]);

  const LargeList = ({ data }) => {
    const height=window.innerHeight-120;
    const isLaptop=useMediaQuery('(min-width:1024px)')
    const is760=useMediaQuery('(min-width:640px)')
    let width=0
    if( isLaptop){
      width=(window.innerWidth*8/12)-20;
    }else if(is760){
      width=Math.max((window.innerWidth*10/12)-20,760)-40;
      // width=760-40;
    }else{
      width=760-40;
    }
   

    const rowRenderer = ({ index, key, style }) => (
      <div key={data[index].name} style={style}>
          <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4}}>
      <Typography sx={{mt:0,mr:5,fontSize:14,width:"140px"}}>{data[index].name}</Typography>
     
     <FormControl size="small" sx={{ width:"80px",minWidth:'80px',ml:2,mt:0 }}>
     <MUISelect
       labelId="demo-simple-select-label"
       id="demo-simple-select"
       value={data[index].dailyTPO}
       MenuProps={MenuProps}
       sx={{height:"24px"}}
       onChange={(event)=>handleTPOChange(event,data[index],"daily")}
     >
       {data[index].tpo_list.map((tpo,index) => (
       <MenuItem value={tpo}>{tpo}</MenuItem>
       ))}
     </MUISelect>
   </FormControl>
   <FormControl size="small" sx={{ width:"80px",minWidth:'80px',ml:2,mt:0 }}>
     <MUISelect
       labelId="demo-simple-select-label"
       id="demo-simple-select"
       value={data[index].weeklyTPO}
       sx={{height:"24px"}}
       MenuProps={MenuProps}
       onChange={(event)=>handleTPOChange(event,data[index],"weekly")}
     >
       {data[index].tpo_list.map((tpo,index) => (
       <MenuItem value={tpo}>{tpo}</MenuItem>
       ))}
     </MUISelect>
   </FormControl>
   <FormControl size="small" sx={{ width:"80px",minWidth:'80px',ml:2,mt:0 }}>
     <MUISelect
       labelId="demo-simple-select-label"
       id="demo-simple-select"
       value={data[index].monthlyTPO}
       sx={{height:"24px"}}
       MenuProps={MenuProps}
       onChange={(event)=>handleTPOChange(event,data[index],"monthly")}
     >
       {data[index].tpo_list.map((tpo,index) => (
       <MenuItem value={tpo}>{tpo}</MenuItem>
       ))}
     </MUISelect>
   </FormControl>
   <FormControl size="small" sx={{ width:"80px",minWidth:'80px',ml:2,mt:0 }}>
     <MUISelect
       labelId="demo-simple-select-label"
       id="demo-simple-select"
       value={data[index].yearlyTPO}
       sx={{height:"24px"}}
       MenuProps={MenuProps}
       onChange={(event)=>handleTPOChange(event,data[index],"yearly")}
     >
       {data[index].tpo_list.map((tpo,index) => (
       <MenuItem value={tpo}>{tpo}</MenuItem>
       ))}
     </MUISelect>
   </FormControl>
   </Box>
      </div>
    );
  
    console.log("width height=",width,height)
    return (
      <List
      width={width}
      height={height}
      rowCount={data.length}
      rowHeight={40}
      rowRenderer={rowRenderer}
      // autoHeight
      overscanRowCount={10}
      containerProps={{
        overflow: 'visible',
      }}
    />

    );
  };

  
  

/**
 * API response handler  
 */  
   useEffect(() => {
    if(loadedDefault){
      if(responseDataDefault!=null){
          
          setSelectedInstrument(responseDataDefault.data.default_instrument);
          const tpoData=processTPOData(tpoGroups,responseDataDefault);
          console.log("MP Settings : user setting response=",responseDataDefault,tpoData)
          setUIDataState((prevState)=>({
            ...prevState,
            tpoGroupData:tpoData    
          }));
        
          setSettingsState({settingsData:responseDataDefault,isUserSettingsLoaded:true,apiTableData:responseDataDefault.data.page_wise_hidden_columns});
      }
      else if(errorDefault!==null){
        setSettingsState((prevState)=>({
          ...prevState,
          isUserSettingsLoaded:true
        }))
        console.log("Error data=",errorDefault);
        setMsgState({open:true,msg:errorDefault?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        //if unauthorized then redirec it to login page
        if(errorDefault?.response?.status === 401 || errorDefault?.response?.status === 403){
          console.log("status received =",errorDefault?.response?.status)
          navigate(from, { replace: true });
        }
    }
    resetDefault();
    }
  },[loadedDefault,responseDataDefault]);

  /**
 * API response handler  for user settings save
 */  
   useEffect(() => {
    if(loadedSettings){
    
      if(responseDataSettings!=null){
        setSettingsState((prevState)=>({
            ...prevState,
            settingsData:responseDataSettings
          }));
          setUserSettings(responseDataSettings);
          console.log("Settings : user setting response=",responseDataSettings)
          setMsgState({open:true,msg:responseDataSettings?.message ?? `${TEXT_MSGS.USER_SETTINGS_SAVED}`,severity:"success"});
          props.saveHandler(responseDataSettings);
          async function saveFCMToken(){
            let fcmToken=null;
            try {
              fcmToken = await requestNotificationPermission();
              console.log("Login FCM Token:", fcmToken,responseData.role);
            } catch (error) {
              console.warn("Login FCM token not retrieved. User may have denied notifications.");
            }
            if (fcmToken) {
              // await sendFcmTokenToServer(fcmToken,accessToken);
              executeAPIToken(URL.SAVE_FCM_TOKEN,"POST",{fcm_token:fcmToken})
            } else {
              console.log("Login  Skipping FCM token submission due to permission denial.");
            }
            // navigate(from, { replace: true });
          }

          if(isPro2User && isNotificationChanged){
            if(currentSettings.data.browser_push_notifications){
              // console.log("Registering FCM token");
              saveFCMToken();
            }else{
              // console.log("Degistering FCM token");
              deregisterFCMToken();
            }    
          }
       
      }
      else if(errorSettings!==null){
        console.log("Error data=",errorSettings);
        setMsgState({open:true,msg:errorSettings?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        //if unauthorized then redirect it to login page
        if(errorSettings?.response?.status === 401 || errorSettings?.response?.status === 403){
          console.log("status received =",errorSettings?.response?.status)
          navigate(from, { replace: true });
        }
    }
    resetSettings();
    }
  },[loadedSettings,responseDataSettings]);

  //FCM token save reponse handler
  useEffect(() => {
    if(loadedToken){
      if(responseDataToken!=null){
          console.log("Save FCM Token=",responseDataToken)
          
      }
      else if(errorToken!==null){
       
        setMsgState({open:true,msg:errorToken?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        //if unauthorized then redirec it to login page
        if(errorToken?.response?.status === 401 || errorToken?.response?.status === 403){
          console.log("status received =",errorToken?.response?.status)
          navigate(from, { replace: true });
        }
    }
    resetToken();
    }
  },[loadedToken,responseDataToken]);


    //execute the data fetch on the component mount
    useEffect(()=>{
      // let data={
      //   "symbol": "NIFTY",
      //   "instruments": 1,
      //   // "tpoList": 1,
      //   // "snakechart_data": 1
      //   "user_settings_form" : 1
      // }
      // executeAPI(URL.MPCHART_UIDATA,"POST",data); 
      let responseData=UIData;
      const tpoData=processTPOData(responseData.tpo_groups,userSettings);
      const filteredInstruments = responseData.instruments.filter(item => !item.series && !item.name.endsWith("_SPREAD"));
    
     setUIDataState({tpoGroupData:tpoData,tpoGroups:responseData.tpo_groups,instrumentData:filteredInstruments, isTPOLoaded:true,tableData:responseData.user_settings_form.page_wise_columns});
     setOptions (filteredInstruments);
    },[]);
  
    const processTPOData=(data,userSettings)=>{
      let processedData=[];
      for (const key in data) {
        // console.log(key, data[key])
        let temp=data[key];
        temp.key=key;
        //  temp.name=key;
        temp.name=key.replace(/_OPT/,' OPTIONS');
        // console.log("daily tpo in settings =",userSettings.data.tf_tpo_map[key].daily);
        if(key in userSettings.data.tf_tpo_map){
          temp.dailyTPO=userSettings.data.tf_tpo_map[key].daily
          temp.weeklyTPO=userSettings.data.tf_tpo_map[key].weekly
          temp.monthlyTPO=userSettings.data.tf_tpo_map[key].monthly
          temp.yearlyTPO=userSettings.data.tf_tpo_map[key].yearly
          processedData.push(temp);
        } else {
          temp.dailyTPO=data[key].default_tpo;
          temp.weeklyTPO=data[key].default_tpo*data[key].tf_factor.weekly;
          temp.monthlyTPO=data[key].default_tpo*data[key].tf_factor.monthly;
          temp.yearlyTPO=data[key].default_tpo*data[key].tf_factor.yearly;
          processedData.push(temp);
        }

      }
      return processedData;
    }

    /**
   * API response handler  
   */  
     useEffect(() => {
      if(loaded){
        if(responseData!=null){
          responseData.instruments = responseData.instruments.map((item) =>
            transformAndReverseMap(item)
          );
            console.log("MPCHart UI Data response=",responseData)
          const tpoData=processTPOData(responseData.tpo_groups,userSettings);
          console.log("MP Settings 2 MPCHart UI Data response=",responseData,tpoData)
           setUIDataState({tpoGroupData:tpoData,tpoGroups:responseData.tpo_groups,instrumentData:responseData.instruments, isTPOLoaded:true,tableData:responseData.user_settings_form.page_wise_columns});
           setOptions (responseData.instruments);
        }
        else if(error!==null){
          setUIDataState({tpoGroupData:undefined,tpoGroups:undefined,instrumentData:undefined,isTPOLoaded:true,tableData:undefined});
          console.log("Error data=",error);
          setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
          //if unauthorized then redirec it to login page
          if(error?.response?.status === 401 || error?.response?.status === 403){
            console.log("status received =",error?.response?.status)
            navigate(from, { replace: true });
          }
      }
      reset();
      }
    },[loaded,responseData]);
    
    useEffect(()=>{
      console.log("searchText=", searchText );
      if(instrumentData!=undefined){
        if(searchText=="")
        setOptions(instrumentData)
        else{
          const temp=instrumentData.filter((option) => containsText(option, searchText))
          setOptions(temp);
        }
      }
    },[searchText])
  
    const handleInstrumentChange = (event) => {
      // setSelectedInstrument(event.target.value);
      setSelectedInstrument(event);
    }  

    const handleVPChange = (event) => {
      setVPType(event.target.value);
    };

    const handleVAChange = (event) => {
      setVAType(event.target.value);
    };

    const handleLocaleChange = (event) => {
      setNumberFormatLocale(event.target.value);
    };

    const handleDataTypeChange = (event) => {
      console.log(event.target.value);
      setDataTypeContracts(event.target.value==="true");
    };

    const handleOFZoomChange = (event) => {
      setOrdeflowZoomType(event.target.value);
    };

    const handleTPOChange=(event,data,type)=>{
      let newData=JSON.parse(JSON.stringify(tpoGroupData));
      let index=newData.findIndex(item=>item.key==data.key)
      if(index==-1)
      return;

      if(type=="daily"){
        newData[index].dailyTPO=event.target.value;
      }
      else if(type=="weekly"){
        newData[index].weeklyTPO=event.target.value;
      }
      else if(type=="monthly"){
        newData[index].monthlyTPO=event.target.value;
      }
      else if(type=="yearly"){
        newData[index].yearlyTPO=event.target.value;
      }
      setUIDataState((prevState) => ({
        ...prevState,
       tpoGroupData:newData
      }));
      
    }

    const disableAutosave=()=>{
      setAutosaveInterval(0);
    }

    const saveSettings=()=>{
        // let newData=JSON.parse(JSON.stringify(userSettings));
        let newData={};
        newData.data={};
        
      newData.data.default_instrument=selectedInstrument
      newData.data.tf_tpo_map=userSettings.data.tf_tpo_map;
     
      for(let i=0;i<tpoGroupData.length;i++){
        if(tpoGroupData[i].key in newData.data.tf_tpo_map){
          newData.data.tf_tpo_map[tpoGroupData[i].key].daily=tpoGroupData[i].dailyTPO;
          newData.data.tf_tpo_map[tpoGroupData[i].key].weekly=tpoGroupData[i].weeklyTPO;
          newData.data.tf_tpo_map[tpoGroupData[i].key].monthly=tpoGroupData[i].monthlyTPO;
          newData.data.tf_tpo_map[tpoGroupData[i].key].yearly=tpoGroupData[i].yearlyTPO;

         
        } else {
          
          newData.data.tf_tpo_map[tpoGroupData[i].key] = {}
          newData.data.tf_tpo_map[tpoGroupData[i].key].daily=tpoGroupData[i].dailyTPO;
          newData.data.tf_tpo_map[tpoGroupData[i].key].weekly=tpoGroupData[i].weeklyTPO;
          newData.data.tf_tpo_map[tpoGroupData[i].key].monthly=tpoGroupData[i].monthlyTPO;
          newData.data.tf_tpo_map[tpoGroupData[i].key].yearly=tpoGroupData[i].yearlyTPO;
        }
      }
      newData.data.show_global_vp=showGlobalVolumeProfile;
      newData.data.show_all_vp=showAllVolumeProfile;
      newData.data.show_profile_vp_numbers=showAllVolumeNumber;
      newData.data.show_split_profile_oi_spikes=showOISpikes;
      newData.data.show_split_profile_va_markers=showTpoVAMarkers;
      newData.data.show_global_vp_numbers=showGlobalVolumeNumberProfile;
      newData.data.show_ui_npoc_krl=showUiNpocKrl;
      newData.data.show_krl=showKrl;
      newData.data.custom_krl_display=showCustomKrl;
      newData.data.custom_krl_overlapping_anchoring=customKrlMultipleAnchor;
      newData.data.show_2sd_band_custom_anchored_krl=anchorVwap2sdDisplay;

 

      newData.data.va_type=vaType;
      newData.data.vp_type=vpType;
      newData.data.max_ndays_for_composite=parseInt(maxCompositeDays);
    //   newData.data.default_lc_ndays=parseInt(defaultLineChartNDays);
      newData.data.autosave_interval=parseInt(autosaveInterval);
      newData.data.number_format_locale=numberFormatLocale;
      newData.data.data_in_contracts=dataTypeContracts;
      if(isPro2User)
        newData.data.browser_push_notifications=browserPushNotification;


      
   
      
      setCurrentSettings(newData);
      console.log("Saved setting=",newData);

      let data={"data":newData};

      executeAPISettings(URL.UPDATE_USER_SETTING,"POST",newData.data);

      
     
    }
    useEffect(()=>{
      setSelectedInstrument(settingsData.data.default_instrument);
      setShowGlobalVolumeProfile(settingsData.data.show_global_vp);
      setShowAllVolumeProfile(settingsData.data.show_all_vp);
      setShowGlobalVolumeNumberProfile(settingsData.data.show_global_vp_numbers);
      setShowAllVolumeNumber(settingsData.data.show_profile_vp_numbers);
      setShowOISpikes(settingsData.data.show_split_profile_oi_spikes);
      setShowTpoVAMarkers(settingsData.data.show_split_profile_va_markers);
      setShowUiNpocKrl(settingsData.data.show_ui_npoc_krl);
      setShowKrl(settingsData.data.show_krl);
      setShowCustomKrl(settingsData.data.custom_krl_display);
      setCustomKrlMultipleAnchor(settingsData.data.custom_krl_overlapping_anchoring);
      setAnchorVwap2sdDisplay(settingsData.data.show_2sd_band_custom_anchored_krl);
      setShowOFMetadata(settingsData.data.show_metadata_marker_of)
      setShowOFNPOC(settingsData.data.show_ui_npoc_krl_of);
      setOrdeflowZoomType(settingsData.data.of_zoom_type?settingsData.data.of_zoom_type:OF_ZOOM_TYPE.ASYMMETRIC);
      setVPType(settingsData.data.vp_type)
      setVAType(settingsData.data.va_type)
      // setOptions (instrumentData);
      setMaxCompositeDays(settingsData.data.max_ndays_for_composite);
      setDefaultLineChartNDays(settingsData.data.default_lc_ndays);
      setAutosaveInterval(settingsData.data.autosave_interval);
      setNumberFormatLocale(settingsData.data.number_format_locale);
      setDataTypeContracts(settingsData.data.data_in_contracts);
      if(isPro2User)
        setBrowserPushNotification(settingsData.data.browser_push_notifications!=undefined?settingsData.data.browser_push_notifications:false);

    },[settingsData])

    const discardChanges=()=>{
      setUIDataState((prevState) => ({
        ...prevState,
       isTPOLoaded:false,
      }));
      setOptions (undefined);
      setSelectedInstrument(settingsData.data.default_instrument);
      setShowGlobalVolumeProfile(settingsData.data.show_global_vp);
      setShowAllVolumeProfile(settingsData.data.show_all_vp);
      setShowGlobalVolumeNumberProfile(settingsData.data.show_global_vp_numbers);
      setShowAllVolumeNumber(settingsData.data.show_profile_vp_numbers);
      setShowOISpikes(settingsData.data.show_split_profile_oi_spikes);
      setShowTpoVAMarkers(settingsData.data.show_split_profile_va_markers);
      setShowUiNpocKrl(settingsData.data.show_ui_npoc_krl);
      setShowKrl(settingsData.data.show_krl);
      setShowCustomKrl(settingsData.data.custom_krl_display);
      setCustomKrlMultipleAnchor(settingsData.data.custom_krl_overlapping_anchoring);
      setAnchorVwap2sdDisplay(settingsData.data.show_2sd_band_custom_anchored_krl);
      setShowOFMetadata(settingsData.data.show_metadata_marker_of)
      setShowOFNPOC(settingsData.data.show_ui_npoc_krl_of);
      setOrdeflowZoomType(settingsData.data.of_zoom_type?settingsData.data.of_zoom_type:OF_ZOOM_TYPE.ASYMMETRIC);
      setVPType(settingsData.data.vp_type)
      setVAType(settingsData.data.va_type)
      setOptions (instrumentData);
      setMaxCompositeDays(settingsData.data.max_ndays_for_composite);
      setDefaultLineChartNDays(settingsData.data.default_lc_ndays);
      setAutosaveInterval(settingsData.data.autosave_interval);
      setNumberFormatLocale(settingsData.data.number_format_locale);
      setDataTypeContracts(settingsData.data.data_in_contracts);
      if(isPro2User)
        setBrowserPushNotification(settingsData.data.browser_push_notifications!=undefined?settingsData.data.browser_push_notifications:false);

      setSettingsState((prevState)=>({
        ...prevState,
        apiTableData:settingsData.data.page_wise_hidden_columns  
      }))
     
      const tpoData=processTPOData(tpoGroups,settingsData);
      // console.log(tpoData)
      setUIDataState({tpoGroupData:tpoData,tpoGroups:tpoGroups,instrumentData:instrumentData, isTPOLoaded:true,tableData:tableData});
      
    }

    const resetToDefault=()=>{
      setSettingsState((prevState)=>({
        ...prevState,
        isUserSettingsLoaded:false
      }))
      // /api/user/settings?reset=true
      executeAPIDefault(`${URL.USER_SETTING}?reset=true`,"GET",{});

    }

/**
 * autoclosing of the snackbar msg bar 
 */ 
   const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
};

  const handleColumnChange=(event,col,type)=>{
    if(event.target.checked){
      console.log("col,type",col,type)
      let data=JSON.parse(JSON.stringify(apiTableData));
      if(!(type in data)) data[type]=[];
      // console.log(data);
      data[type].push(col);
      // console.log(data[type]);
      setSettingsState((prevState)=>({
        ...prevState,
        apiTableData:data
      }));
    }else{
      let data=JSON.parse(JSON.stringify(apiTableData));
      data[type] = data[type].filter(item => item !== col)
      console.log(data[type]);
      setSettingsState((prevState)=>({
        ...prevState,
        apiTableData:data
      }));
    }
  }

  const handleNotificationChange=(val)=>{
    setBrowserPushNotification(val);
    setNotificationChanged(true)
   

  }

  return (
    <Modal open={modalOpen} onClose={handleModalClose}>
       <ConfigProvider
    theme={{
      // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
      components: {
        
        Select: {
          colorPrimary: '#0d203f',
          colorBgContainer:"#0000ff",
          optionActiveBg:"#22314E",
          colorText:"#FFFFFF",
          optionSelectedBg:"#35445E",
          optionSelectedColor:"#FFFFFF",
          selectorBg:"transparent",
          colorTextPlaceholder:"#ffffff",
          colorIcon:"#ff0000",
          colorTextDisabled:"#899499"
          // algorithm: true, // Enable algorithm
        },
        
      },
    }}
  >
    <>
    {isTPOLoaded && isUserSettingsLoaded && options!=undefined?
     <Box sx={style} >
         <CancelOutlinedIcon
                onClick={() => handleModalClose()}
                sx={{
                ml: 0, mt: 0,top: `calc(5% - 12px)`,left: `calc(90% - 13px)`,
                 height: 24, width: 26, cursor: 'pointer', position: 'fixed', zIndex: 9999
                }}
            />
    <Grid container spacing={2}>
    <Grid item mobile={0} tablet={1} laptop={2}>
     
    </Grid>
    <Grid item mobile={12} tablet={10} laptop={8} sx={{minWidth:"760px",ml:2,mr:2}}>
    <Box sx={{display:"flex",mr:1,mt:1}}>
      <Typography sx={{mt:2,fontSize:24,fontWeight:'bold'}}>Market Profile - Instrument Specific Settings</Typography>
      <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">Settings specific to the selected instrument in MP Chart</Typography>
              </React.Fragment>
            }
          >
          <InfoIcon sx={{ml:1,marginTop:"20px"}}></InfoIcon>
        </HtmlTooltip>
    </Box>
      {/* Groups TPO settings */}
      {/* <LargeList data={tpoGroupData} /> */}
      <div ref={parentRef} >
      <Box sx={{backgroundColor:'primaryTheme.shade03',mt:2,pb:2,pr:2,borderRadius:5,width:"100%"}}>
      <Typography sx={{mt:2,pt:2,pb:2,ml:3,fontSize:14,color:"#92a9a9",fontWeight:"bold"}}>Default TPO Size Settings</Typography>
      <Box display="flex" justifyContent="flex-start" sx={{mb:2,ml:4}}>
      <Typography sx={{width:"140px",mr:5,fontSize:14,color:"#92a9a9"}}>Instrument Group</Typography>
      <Typography sx={{width:"80px",ml:2,fontSize:14,color:"#92a9a9"}}>Daily</Typography>
      <Typography sx={{width:"80px",ml:2,fontSize:14,color:"#92a9a9"}}>Weekly</Typography>
      <Typography sx={{width:"80px",ml:2,fontSize:14,color:"#92a9a9"}}>Monthly</Typography>
      <Typography sx={{width:"80px",ml:2,fontSize:14,color:"#92a9a9"}}>Yearly</Typography> 
     </Box>  
     {/* { parentRef.current? */}
     {/* <LargeList data={tpoGroupData} />   */}
     {/* :
     <></>
    }   */}
                   
      {tpoGroupData.filter(item=>item.key==props.selectedInstrumentData.tpo_group).map((item,index) => (
        <>
        <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4}}>
         <Typography sx={{mt:0,mr:5,fontSize:14,width:"140px"}}>{item.name}</Typography>
        
        <FormControl size="small" sx={{ width:"80px",minWidth:'80px',ml:2,mt:0 }}>
        <MUISelect
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={item.dailyTPO}
          MenuProps={MenuProps}
          sx={{height:"24px"}}
          onChange={(event)=>handleTPOChange(event,item,"daily")}
        >
          {item.tpo_list.map((tpo,index) => (
          <MenuItem value={tpo}>{tpo}</MenuItem>
          ))}
        </MUISelect>
      </FormControl>
      <FormControl size="small" sx={{ width:"80px",minWidth:'80px',ml:2,mt:0 }}>
        <MUISelect
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={item.weeklyTPO}
          sx={{height:"24px"}}
          MenuProps={MenuProps}
          onChange={(event)=>handleTPOChange(event,item,"weekly")}
        >
          {item.tpo_list.map((tpo,index) => (
          <MenuItem value={tpo}>{tpo}</MenuItem>
          ))}
        </MUISelect>
      </FormControl>
      <FormControl size="small" sx={{ width:"80px",minWidth:'80px',ml:2,mt:0 }}>
        <MUISelect
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={item.monthlyTPO}
          sx={{height:"24px"}}
          MenuProps={MenuProps}
          onChange={(event)=>handleTPOChange(event,item,"monthly")}
        >
          {item.tpo_list.map((tpo,index) => (
          <MenuItem value={tpo}>{tpo}</MenuItem>
          ))}
        </MUISelect>
      </FormControl>
      <FormControl size="small" sx={{ width:"80px",minWidth:'80px',ml:2,mt:0 }}>
        <MUISelect
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={item.yearlyTPO}
          sx={{height:"24px"}}
          MenuProps={MenuProps}
          onChange={(event)=>handleTPOChange(event,item,"yearly")}
        >
          {item.tpo_list.map((tpo,index) => (
          <MenuItem value={tpo}>{tpo}</MenuItem>
          ))}
        </MUISelect>
      </FormControl>
      </Box>
      </>
      ))}
                       
      </Box>
      </div>
      {/* Volume Profile Settings */}
      <Box sx={{display:"flex",mr:1,mt:2}}><Typography sx={{mt:2,fontSize:24,fontWeight:'bold'}}>Market Profile - Global Settings</Typography>
      <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">Global settings applicable across Market Profile Charts for all instruments in general</Typography>
              </React.Fragment>
            }
          >
          <InfoIcon sx={{ml:1,marginTop:"20px"}}></InfoIcon>
        </HtmlTooltip>
      </Box>
      <Box sx={{backgroundColor:'primaryTheme.shade03',mt:2,ml:0,borderRadius:5,pb:2,mb:4,width:"100%"}}>
      
      <FormControl variant="standard" size="small" sx={{ minWidth:"80%",ml:2,mt:1 }}>
     
      <Box display="flex" justifyContent="flex-start" sx={{mt:1,ml:2,mb:1}}>
      <Box sx={{width:"272px",display:"flex",mr:1}}>
      <Typography sx={{marginTop:"4px",mr:1,fontSize:14,width:"150px"}}>Default Instrument</Typography>
      <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Default instrument when a market profile chart is opened from menu.</Typography>
                  {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
                  {"It's very engaging. Right?"} */}
                </React.Fragment>
              }
            >
            <InfoIcon sx={{marginTop:"5px"}}></InfoIcon>
          </HtmlTooltip>
          </Box>
                    {/* <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedInstrument}
                        onChange={handleInstrumentChange}
                        onOpen={autoFocus}
                        label="Instrument"
                        sx={{minWidth: 230,border:"1px solid #fff",borderColor:"#fff",paddingLeft:"4px",paddingTop:"4px",marginTop:"2px"}}
                        MenuProps={MenuProps}
                        onClose={() => setSearchText("")}
                        // This prevents rendering empty string in Select's value
                        // if search text would exclude currently selected option.
                        // renderValue={() => selectedInstrument}
                        >
                        
                        <ListSubheader>
                            <TextField
                            size="small"
                            // Autofocus on textfield
                            autoFocus
                            // ref={focusUsernameInputField}
                            inputRef={inputRef}
                            variant='standard'
                            placeholder="Type to search..."
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                                )
                            }}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key !== "Escape") {
                                // Prevents autoselecting item while typing (default Select behaviour)
                                e.stopPropagation();
                                }
                            }}
                            />
                        </ListSubheader>  
                        {options.map((item,index) => (
                          <MenuItem key={item.name} value={item.instr}>{item.name}</MenuItem>
                        ))}
                    </Select> */}
                        <Select
                     getPopupContainer={(triggerNode) => triggerNode.parentNode} 
                        // variant="borderless"
                        suffixIcon={<ArrowDropDownIcon  style={{ color: 'white' }} />}
                        ref={selectRef}
                        showSearch
                        style={{
                          width: 280,
                          height:28,
                          maxHeight:28,
                          // marginTop:-4,
                          color:"#FFFFFF",
                          // backgroundColor: 'green'
                        }}
                       
                        placeholder="Search Instrument"
                        optionFilterProp="children"
                        defaultValue={"NIFTY"}
                        value={selectedInstrument}
                        dropdownStyle={{ backgroundColor: '#0d203f',color:'#00ff00',minWidth:"240px"}}
                        // onChange={handleSelectChange}
                        onChange={handleInstrumentChange}
                        onSearch={(value)=>setSearchText(value)}
                             filterOption={(input, option) => {
                          const words = input.trim().toLowerCase().split(' ');
                          return words.every(word => option.props.children.toLowerCase().includes(word));
                          
                          // return (
                          //   // option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          // );
                        }}
                      >
                      {options.map((item) => (
                        <Option key={item.instr} value={item.instr}  style={{fontSize:"12px"}}>

                          {item.name}
                        </Option>
                      ))}
                </Select> 
                    </Box>
                </FormControl> 
        <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
          <Box sx={{width:"272px",display:"flex"}}>
            <Typography sx={{mr:1,mt:1,fontSize:14}}>Show Global Volume Profile</Typography>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Show Global Volume Profile by Default. Can disable to hide the Global Volume Profile for all instruments.</Typography>
                  {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
                  {"It's very engaging. Right?"} */}
                </React.Fragment>
              }
            >
            <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
          </HtmlTooltip>
        </Box>
        
        <Switch checked={showGlobalVolumeProfile} color="secondary"
            onChange={()=>setShowGlobalVolumeProfile(!showGlobalVolumeProfile)}
            inputProps={{ 'aria-label': 'controlled' }}></Switch>
        </Box>
        
        {/* <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
        <Box sx={{width:"272px",display:"flex"}}>
            <Typography sx={{mr:1,mt:1,fontSize:14}}>Show All Volume Profiles</Typography>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Always Show all Volume Profiles by Default</Typography>
                </React.Fragment>
              }
            >
            <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
          </HtmlTooltip>
        </Box>
        
          <Switch checked={showAllVolumeProfile} color="secondary"
            onChange={()=>setShowAllVolumeProfile(!showAllVolumeProfile)}
            inputProps={{ 'aria-label': 'controlled' }}></Switch>
        </Box> */}

        <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
          <Box sx={{width:"272px",display:"flex"}}>
              <Typography sx={{mr:1,mt:1,fontSize:14}}>Show Global Profile Volume Numbers</Typography>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">Setting to show/hide Volume Numbers on the Global Volume profile</Typography>
                  </React.Fragment>
                }
              >
              <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
            </HtmlTooltip>
          </Box>
        
          <Switch checked={showGlobalVolumeNumberProfile} color="secondary"
            onChange={()=>setShowGlobalVolumeNumberProfile(!showGlobalVolumeNumberProfile)}
            inputProps={{ 'aria-label': 'controlled' }}></Switch>
        </Box>
        <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
          <Box sx={{width:"272px",display:"flex"}}>
                <Typography sx={{mr:1,mt:1,fontSize:14}}>Show Volume Numbers</Typography>
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Setting to show/hide Volume numbers per level per individual Volume Profile in chart</Typography>
                    </React.Fragment>
                  }
                >
                <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
              </HtmlTooltip>
            </Box>
     
          <Switch checked={showAllVolumeNumber} color="secondary"
            onChange={()=>setShowAllVolumeNumber(!showAllVolumeNumber)}
            inputProps={{ 'aria-label': 'controlled' }}></Switch>
        </Box>

        <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
          <Box sx={{width:"272px",display:"flex"}}>
                <Typography sx={{mr:1,mt:1,fontSize:14}}>Split profile OI Spike display *</Typography>
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Setting to show/hide Green/Red fluorescent markers for OI spikes in MP Chart split profile view.
                      <br/><br/>* Needs refresh for saved setting to take effect
                      </Typography>
                    </React.Fragment>
                  }
                >
                <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
              </HtmlTooltip>
            </Box>
     
          <Switch checked={showOISpikes} color="secondary"
            onChange={()=>setShowOISpikes(!showOISpikes)}
            inputProps={{ 'aria-label': 'controlled' }}></Switch>
        </Box>

        <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
          <Box sx={{width:"272px",display:"flex"}}>
                <Typography sx={{mr:1,mt:1,fontSize:14}}>Split profile TPO wise VWAP/VPOC markers display *</Typography>
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Setting to show/hide TPO wise VWAP and VPOC markers in MP Chart split profile view
                      <br/><br/>* Needs refresh for saved setting to take effect
                      </Typography>
                    </React.Fragment>
                  }
                >
                <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
              </HtmlTooltip>
            </Box>
     
          <Switch checked={showTpoVAMarkers} color="secondary"
            onChange={()=>setShowTpoVAMarkers(!showTpoVAMarkers)}
            inputProps={{ 'aria-label': 'controlled' }}></Switch>
        </Box>

        <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
          <Box sx={{width:"272px",display:"flex"}}>
              <Typography sx={{mr:1,mt:1,fontSize:14}}>Show NPOC Lines</Typography>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">Setting to show/hide NPOC (Unvisited VPOC) lines in Market Profile Charts. <br/>Enabled by default.</Typography>
                  </React.Fragment>
                }
              >
              <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
            </HtmlTooltip>
          </Box>
          <Switch checked={showUiNpocKrl} color="secondary"
            onChange={()=>setShowUiNpocKrl(!showUiNpocKrl)}
            inputProps={{ 'aria-label': 'controlled' }}></Switch>
        </Box>

        <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
          <Box sx={{width:"272px",display:"flex"}}>
              <Typography sx={{mr:1,mt:1,fontSize:14}}>Autogenerated KRLs *</Typography>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">* This feature is not available for users with a Free plan.
                    <br/><br/>Enable it to show autogenerated Key Reference Level (KRL) lines in MP Charts for NF/BNF only, based on user access level. 
                    <br/><br/>There are a few KRLs like FA, A singles, Trend-day VWAP, SMA etc generated by system for NIFTY and BANKNIFTY instruments only and they are shown once this setting is enabled.
                    <br/><br/>Note: this setting does not affect Anchored KRLs and you need an Expert or higher plan with Custom KRLs enabled to create/see Anchored KRLs
                    <br/><br/>By default this setting is disabled.</Typography>
                  </React.Fragment>
                }
              >
              <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
            </HtmlTooltip>
          </Box>
          <Switch checked={showKrl} color="secondary"
            onChange={()=>setShowKrl(!showKrl)}
            inputProps={{ 'aria-label': 'controlled' }}></Switch>
        </Box>

        <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
          <Box sx={{width:"272px",display:"flex"}}>
              <Typography sx={{mr:1,mt:1,fontSize:14}}>Custom KRLs *</Typography>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">* This feature is not available for users with a Free plan.
                    <br/><br/>Enable this setting to allow users to create, manage and show Custom KRL and Anchored KRL markers in MP charts.
                    <br/><br/>Note: Only Expert or higher plan users are allowed to create Anchored KRLs and this setting is required to be enabled for that.</Typography>
                  </React.Fragment>
                }
              >
              <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
            </HtmlTooltip>
          </Box>
          <Switch checked={showCustomKrl} color="secondary"
            onChange={()=>setShowCustomKrl(!showCustomKrl)}
            inputProps={{ 'aria-label': 'controlled' }}></Switch>
        </Box>

        <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
          <Box sx={{width:"272px",display:"flex"}}>
              <Typography sx={{mr:1,mt:1,fontSize:14}}>Overlap Anchored KRLs *</Typography>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">* This setting is applicable only for Expert or higher plan users.
                    <br/><br/>Allow for different anchored KRLs to continue to plot, aka overlap, even if a new Anchor was defined before the previous Anchor could end.</Typography>
                  </React.Fragment>
                }
              >
              <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
            </HtmlTooltip>
          </Box>
          <Switch checked={customKrlMultipleAnchor} color="secondary"
            onChange={()=>setCustomKrlMultipleAnchor(!customKrlMultipleAnchor)}
            inputProps={{ 'aria-label': 'controlled' }}></Switch>
        </Box>

        <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
          <Box sx={{width:"272px",display:"flex"}}>
              <Typography sx={{mr:1,mt:1,fontSize:14}}>2SD Bands on Anchored VWAP *</Typography>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">* This setting is applicable only for Expert or higher plan users.
                    <br/><br/>Global setting to disable all 2SD up and down bands for all Custom Anchored VWAP KRLs.
                    <br/>By default this setting is enabled.</Typography>
                  </React.Fragment>
                }
              >
              <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
            </HtmlTooltip>
          </Box>
          <Switch checked={anchorVwap2sdDisplay} color="secondary"
            onChange={()=>setAnchorVwap2sdDisplay(!anchorVwap2sdDisplay)}
            inputProps={{ 'aria-label': 'controlled' }}></Switch>
        </Box>

        <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
            <Box sx={{width:"272px",display:"flex"}}>
                <Typography sx={{mr:1,mt:1,fontSize:14}}>Volume Profile Display</Typography>
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Volume Profile Display</Typography>
                      How to show the volume profile?
                      <li>None: No Volume profile shown by default.</li>
                      <li>Aside: Show Volume profile side by side to TPO profile.</li>
                      <li>Background: Show Volume Profile behind TPO profile. Cannot display Volume profile numbers, in this option.</li>
                      <li>Alone: Show only Volume Profile bars.</li>
                      
                    </React.Fragment>
                  }
                >
                <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
              </HtmlTooltip>
            </Box>
     
          <RadioGroup
          row
          sx={{ml:1}}
          size='small'
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel value={VP_TYPE.NONE}
            control={<Radio checked={vpType === VP_TYPE.NONE} onChange={handleVPChange} size="small" />} 
            label={<Typography sx={{fontSize:14}}>None</Typography>} />
          <FormControlLabel value={VP_TYPE.ASIDE}
            control={<Radio checked={vpType === VP_TYPE.ASIDE} onChange={handleVPChange} size="small" />} 
            label={<Typography sx={{fontSize:14}}>Aside</Typography>} />
          <FormControlLabel value={VP_TYPE.BACKGROUND} 
          control={<Radio checked={vpType === VP_TYPE.BACKGROUND} onChange={handleVPChange} size="small" />} 
          label={<Typography sx={{fontSize:14}}>Background</Typography>} />
          <FormControlLabel value= {VP_TYPE.ALONE}
          control={<Radio checked={vpType === VP_TYPE.ALONE} onChange={handleVPChange} size="small" />} 
          label={<Typography sx={{fontSize:14}}>Alone</Typography>} />
          
        </RadioGroup>
      </Box>
      <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
           <Box sx={{width:"272px",display:"flex"}}>
                <Typography sx={{mr:1,mt:1,fontSize:14}}>Value Area Default</Typography>
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Display TPO based / Volume based or Hybrid type Value Area</Typography>
                      <br/>* Volume based: The VA, VPOC and VWAP values will all be Volume wise calculations.
                      <br/>* TPO based: The VA, VPOC and VWAP values will all be displayed based on TPO data based calcuations only.
                      <br/>* Hybrid: The VA and VPOC is TPO based, but the VWAP value shown will be Volume based.
                    </React.Fragment>
                  }
                >
                <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
              </HtmlTooltip>
            </Box>
     
          <RadioGroup
          row
          sx={{ml:1}}
          size='small'
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel value="vol" 
          control={<Radio checked={vaType === 'vol'} onChange={handleVAChange} size="small" />} 
          label={<Typography sx={{fontSize:14}}>Volume Based</Typography>} />
          <FormControlLabel value="tpo" 
          control={<Radio checked={vaType === 'tpo'} onChange={handleVAChange} size="small" />} 
          label={<Typography sx={{fontSize:14}}>TPO Based</Typography>} />
          <FormControlLabel value="hybrid" 
          control={<Radio checked={vaType === 'hybrid'} onChange={handleVAChange} size="small" />} 
          label={<Typography sx={{fontSize:14}}>Hybrid</Typography>} />
          
          
        </RadioGroup>
      </Box>
      <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
      <Box sx={{width:"272px",display:"flex"}}>
                <Typography sx={{mr:1,mt:1,fontSize:14}}>Max full profiles in Composite </Typography>
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Max full profile days in Composite [2-15]</Typography>
                      These many number of days will be plot with 30 mins per TPO alphabet. After this limit, the composite will be plot with each day profile represented as single TPO alphabet
                    </React.Fragment>
                  }
                >
                <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
              </HtmlTooltip>
            </Box>
     
          <TextField size="small" type="number" value={maxCompositeDays} 
             onChange={(e) => {
              // if(e.target.value>15)setMaxCompositeDays(15)
              // else if(e.target.value=="")setMaxCompositeDays(maxCompositeDays)
              // else
              if(e.target.value<=15 && e.target.value>=2)
               setMaxCompositeDays(e.target.value)
            }}
            sx={{height:"25px",ml:1,padding:"3px"}} inputProps={{ min: 2, max: 15,height:"25px",padding:"3px" }} />
      </Box>

      {/* <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
        <Box sx={{width:"272px",display:"flex"}}>
          <Typography sx={{mr:1,mt:1,fontSize:14}}>Default nDays for Line Charts</Typography>
          <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Default number of days of data to plot on Line Charts [-1 to 6]</Typography>
                      You can override this value on Line Charts page by explicitly specifying the nDays parameter in URL.
                    </React.Fragment>
                  }
                >
              <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
            </HtmlTooltip>
        </Box>
        <TextField size="small" type="number" value={defaultLineChartNDays} 
             onChange={(e) => {
              if(e.target.value<=6 && e.target.value>=-1)
                setDefaultLineChartNDays(parseInt(e.target.value))
            }}
            sx={{height:"25px",ml:1,padding:"3px"}} inputProps={{ min: 1, max: 11,height:"25px",padding:"3px" }} />
      </Box> */}

      
      <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%"}}>
        <Box sx={{width:"272px",display:"flex"}}>
          <Typography sx={{mr:1,mt:1,fontSize:14}}>AutoSave interval (<Link onClick={disableAutosave} sx={{ml:0,cursor:"pointer"}} variant="link">disable</Link>)</Typography>
          <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Interval for autosaving MP charts [10-300].</Typography>
                      Default value is 180 (3 minutes).
                      MP charts can be autosaved in repeating intervals based on this setting. Set this to 0 to disable auto saving.
                      If auto saving is disabled you can manually save the chart by clicking the save button on top right.
                    </React.Fragment>
                  }
                >
              <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
            </HtmlTooltip>
        </Box>
        <TextField size="small" type="number" value={autosaveInterval} 
             onChange={(e) => {
              if(e.target.value<=300 && e.target.value>=0)
                setAutosaveInterval(parseInt(e.target.value))
            }}
            sx={{height:"25px",ml:1,padding:"3px"}} inputProps={{ min: 0, max: 300,height:"25px",padding:"3px" }} />
          <Typography sx={{ml:1,mt:1,fontSize:14}}>seconds</Typography>
      </Box>
    </Box>

    {/* Global settings */}
    <Box sx={{display:"flex",mr:1,mt:0}}><Typography sx={{mt:2,fontSize:24,fontWeight:'bold'}}>Global Settings</Typography>
      <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">Global settings applicable across all charts</Typography>
                {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
                {"It's very engaging. Right?"} */}
              </React.Fragment>
            }
          >
          <InfoIcon sx={{ml:1,marginTop:"20px"}}></InfoIcon>
        </HtmlTooltip>
      </Box>
    <Box sx={{backgroundColor:'primaryTheme.shade03',mt:2,pb:2,mb:6,pr:2,borderRadius:5,width:"100%"}}>
    
    <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%",marginTop:"0px"}}>
        <Box sx={{width:"272px",display:"flex"}}>
          <Typography sx={{mr:1,mt:2,fontSize:14}}>Locale for Number Formatting</Typography>
          <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Locale used for formatting large numbers across all pages</Typography>
                      Indian locale formats the numbers in Lakhs, Crores format. <br/>
                      US locale formats data in millions and billions
                    </React.Fragment>
                  }
                >
              <InfoIcon sx={{marginTop:"16px"}}></InfoIcon>
            </HtmlTooltip>
        </Box>
        <FormControlLabel value="en-In" 
          control={<Radio checked={numberFormatLocale === 'en-In'} onChange={handleLocaleChange} size="small"  sx={{mt:1}}/>} 
          label={<Typography sx={{fontSize:14, mt:1}}>Indian Format</Typography>} />
          <FormControlLabel value="en-Us" 
          control={<Radio checked={numberFormatLocale === 'en-Us'} onChange={handleLocaleChange} size="small" sx={{mt:1}} />} 
          label={<Typography sx={{fontSize:14, mt:1}}>US Format</Typography>} />
        
      </Box>
      <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:4,width:"100%",marginTop:"2px"}}>
        <Box sx={{width:"272px",display:"flex"}}>
          <Typography sx={{mr:1,mt:1,fontSize:14}}>OI and Vol Data</Typography>
          <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">OI and Volume data across all pages should be displayed in #Contracts vs Total value?</Typography>
                    </React.Fragment>
                  }
                >
              <InfoIcon sx={{marginTop:"6px"}}></InfoIcon>
            </HtmlTooltip>
        </Box>
        <FormControlLabel value="true" 
          control={<Radio checked={dataTypeContracts} onChange={handleDataTypeChange} size="small" />} 
          label={<Typography sx={{fontSize:14}}>Contracts</Typography>} />
          <FormControlLabel value="false" 
          control={<Radio checked={!dataTypeContracts} onChange={handleDataTypeChange} size="small" />} 
          label={<Typography sx={{fontSize:14}}>Total Value</Typography>} />
        
      </Box>
      {isPro2User?
      <>
       <Box display="flex" justifyContent="flex-start" sx={{ mb: 0, ml: 4, mt: 1, width: "100%" }}>
          <Box sx={{ width: "260px", display: "flex" }}>
            <Typography sx={{ mr: 1, mt: 1, fontSize: 14 }}>Browser Push Notification</Typography>
            <HtmlTooltip
              title={
                    <React.Fragment>
                       <Typography color="inherit">{TEXT_MSGS.INFO_BROWSER_NOTIFICATION_POP_UP}</Typography>
                    </React.Fragment>
                    }
            >
             <InfoIcon sx={{ marginTop: "6px" }}></InfoIcon>
            </HtmlTooltip>
           </Box>

            <Switch checked={browserPushNotification} color="secondary"
               onChange={() => handleNotificationChange(!browserPushNotification)}
              inputProps={{ 'aria-label': 'controlled' }}></Switch>

        </Box> 
        {isNotificationChanged?
        <Box sx={{ml:4}}>
          <Typography  variant="warningText"sx={{ mr: 1, mt: 0,ml:0, fontSize: 14 }}>
            {browserPushNotification?TEXT_MSGS.MSG_NOTIFICATION_ENABLED :TEXT_MSGS.MSG_NOTIFICATION_DISABLED} </Typography> 
            </Box>  
          :
          <></>
        }
        </>
        :<></>
        }    
   
                    
    </Box>

   
    
    </Grid>
    <Grid item mobile={0} tablet={1} laptop={2}>
     
    </Grid>
    
   
</Grid>
<SettingsFooterPopup saveSettings={saveSettings} discardChanges={discardChanges} resetDefault={resetToDefault}>

</SettingsFooterPopup>
</Box>    :
<>
<CircularProgress sx={{marginTop:"20%",marginLeft:"45%"}}/></>}
        <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}} sx={{top:"48px"}} open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
          <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
       </Snackbar>
       </>
       </ConfigProvider>
       </Modal>
  )
}

export default MPSettings
   