import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/styles/styles.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

//Register service worker. Will be executed in background in OS context and handle the notification in background and broswer closed
const registerServiceWorker = async () => {
    if ("serviceWorker" in navigator) {
      try {
        await navigator.serviceWorker.register("/firebase-messaging-sw.js");
        console.log("Firebase Service Worker Registered");
      } catch (error) {
        console.error("Firebase Service Worker Registration Failed", error);
      }
    }
  
};

//disable console logs in prod env
if (process.env.NODE_ENV != "development"){
    console.log = () => {};
    console.error =()=>{};
    console.warn =()=>{};
}   
root.render(
  //TODO: use strict mode in production build. uncooment this
  //https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar
  
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Call this when the app loads
registerServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
