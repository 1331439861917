import { useEffect, useState } from 'react'
import { URL } from '../../common/api/urls';
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import { Snackbar, Box, Select, Typography, Container, CircularProgress, Alert } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import OptionsFooter from './components/options_footer';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import { TEXT_MSGS, SNACKBAR_AUTO_HIDE_DURATION_SHORT } from '../../common/utility/constant';
import { styled, useTheme } from '@mui/material/styles';
import { WATERMARK_CONFIG } from '../../common/utility/constant';
import { ToolbarFixed } from '../../layouts/component.js';
import useAuth from '../../common/hooks/useAuth';
import { USER_ROLE_FIELDS } from '../../common/utility/constant';

import { Table, Card } from "antd";
import "./styles/styles.scss"; // Custom CSS
import { LOCALE_STRING } from "../../common/utility/constant";

const DateFormat = 'DD-MM-YYYY';
const FooterToolbar = styled(ToolbarFixed)(
    ({ theme }) => `
    bottom:0px;
    justify-content:center;
    text-align:center;
    `
)

// Function to apply background color only to Net values
const getNetStyle = (value, reverse) => ({
    color: reverse ? (value > 0 ? "red" : "green") : (value < 0 ? "red" : "green"),
    fontWeight: "bold",
    backgroundColor: reverse ? (value > 0 ? "#ffcccc" : "#ccffcc") : (value < 0 ? "#ffcccc" : "#ccffcc"), // Red for negative, Green for positive
    padding: "5px",
    textAlign: "right",
});

const getNumberStyle = (value) => ({
    padding: "5px",
    textAlign: "right",
});


// Futures Table with Common Header
const futuresColumns = [
    {
        title: "Futures", 
        children: [
            {
                title: "",
                dataIndex: "label",
                key: "label",
                align: "left",
                render: (text) => <div style={{ fontWeight: "bold" }}>{text}</div>,
            },
            {
                title: "",
                dataIndex: "futures",
                key: "futures",
                align: "right",
                render: (value) => <div style={getNumberStyle(value)}>{Number(value).toLocaleString(LOCALE_STRING)}</div>
            },
            {
                title: "",
                dataIndex: "net",
                key: "net",
                render: (value) =>
                    value !== null
                        ? <div style={getNetStyle(value, false)}>{Number(value).toLocaleString()}</div>
                        : <div style={{ fontWeight: 'bold' }}>NET</div>,
            },
        ],
    },
];


// CE and PE Table with Common Headers
const optionsColumns = [
    {
        title: "CE", 
        children: [
            {
                title: "", dataIndex: "ceLabel", key: "ceLabel", align: "left",
                render: (text) => <div style={{ fontWeight: "bold" }}>{text}</div>,
            },
            {
                title: "", dataIndex: "ceValue", key: "ceValue", align: "right",
                render: (value) => <div style={getNumberStyle(value)}>{Number(value).toLocaleString(LOCALE_STRING)}</div>
            },
            {
                title: "",
                dataIndex: "ceNet",
                key: "ceNet",

                render: (value) =>
                    value !== null ? <div style={getNetStyle(value, false)}>{Number(value).toLocaleString(LOCALE_STRING)}</div> : <div style={{ fontWeight: 'bold' }}>NET</div>, // Background color only for Net
            },
        ],
    },

    {
        title: "PE",
        children: [
            {
                title: "", dataIndex: "peLabel", key: "peLabel", align: "left",
                render: (text) => <div style={{ fontWeight: "bold" }}>{text}</div>,
            },
            {
                title: "", dataIndex: "peValue", key: "peValue", align: "right",
                render: (value) => <div style={getNumberStyle(value)}>{Number(value).toLocaleString(LOCALE_STRING)}</div>
            },
            {
                title: "",
                dataIndex: "peNet",
                key: "peNet",

                render: (value) =>
                    value !== null ? <div style={getNetStyle(value, true)}>{Number(value).toLocaleString(LOCALE_STRING)}</div> : <div style={{ fontWeight: 'bold' }}>NET</div>, // Background color only for Net
            },
        ],
    },

];
const getColorForTitle = (title) => {
    if (title === 'FII' || title === 'DII') return '#fff4bd';
    return '#ffc552';
};

const transformApiDataToSections = (apiData) => {
    const participantData = apiData.participant_data;

    return Object.entries(participantData).map(([title, values]) => {
        const longFut = values.long_doi;
        const shortFut = values.short_doi;
        const netFut = (longFut ?? 0) - (shortFut ?? 0);

        const ceLong = values.ce_long_doi;
        const ceShort = values.ce_short_doi;
        const peLong = values.pe_long_doi;
        const peShort = values.pe_short_doi;

        return {
            title,
            color: getColorForTitle(title),
            data: [
                { key: 1, label: 'Longs', futures: longFut, net: null },
                { key: 2, label: 'Shorts', futures: shortFut, net: netFut },
            ],
            options: [
                {
                    key: 1,
                    ceLabel: 'Longs',
                    ceValue: ceLong,
                    ceNet: null,
                    peLabel: 'Longs',
                    peValue: peLong,
                    peNet: null,
                },
                {
                    key: 2,
                    ceLabel: 'Shorts',
                    ceValue: ceShort,
                    ceNet: ceLong - ceShort,
                    peLabel: 'Shorts',
                    peValue: peShort,
                    peNet: peLong - peShort,
                },
            ],
        };
    });
};
export default function PositionTable() {
    const navigate = useNavigate();
    const theme = useTheme();
    const from = "/login";
    const { auth } = useAuth();
    // const [isBasicUser,setIsBasicUser]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))?false:true)
    const [cancel, responseData, error, loaded, reset, executeAPI] = useAxiosWithAuth();
    const [fiiState, setFiiState] = useState({
        date: undefined,
        isLoading: true,
        sections: undefined,
        date_key: "NA"
    });
    const { sections, isLoading, date, date_key } = fiiState


    const [msgState, setMsgState] = useState({
        open: false,
        msg: "",
        severity: "info"
    });

    const { open, msg, severity } = msgState;
    /**
       * autoclosing of the snackbar msg bar 
   */
    const handleClose = (event, reason) => {
        setMsgState({ ...msgState, open: false });
    };

    useEffect(() => {
        document.title = "Participantwise Positions";
        var link = !document.querySelector("link[rel='canonical']") ? document.querySelector("link[rel='canonical']") : document.createElement('link');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', document.location.protocol + '//' + document.location.host + document.location.pathname);
        document.head.appendChild(link);
    }, []);

    useEffect(() => {
        executeAPI(`${URL.POSITION_DATA}?dt=`, "GET", {})
    }, [])

    useEffect(() => {
        if (loaded) {
            if (responseData != null) {
                let transformed=undefined;
                if(responseData.participant_data)
                    transformed= transformApiDataToSections(responseData);
                
                setFiiState((prevState) => ({
                    ...prevState,
                    sections: transformed,
                    isLoading: false,
                    date_key: responseData.date_key
                }))
            }
            else if (error !== null) {


                setMsgState({ open: true, msg: error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`, severity: "info" });
                //if unauthorized then redirec it to login page
                if (error?.response?.status === 401 || error?.response?.status === 403) {
                    console.log("status received =", error?.response?.status)
                    navigate(from, { replace: true });
                }
            }
            reset();
        }
    }, [loaded, responseData]);

    const onChange = (date1, dateString) => {

        setFiiState((prevState) => ({
            ...prevState,
            date: dayjs(date1)
        }))
        let tempDate = dayjs(date1).format(DateFormat)
        executeAPI(`${URL.POSITION_DATA}?dt=${tempDate}`, "GET", {})

    };

    const disabledDate = (current) => {
        return (current && current >= dayjs().endOf('day')) || dayjs(current).day() == 6 || dayjs(current).day() == 0
    }


    return (
        <>
            {!isLoading ?

                <div style={{ padding: "20px", width: "100%" }}>
                    <Box display={'flex'} flexDirection={'row'}>
                        <DatePicker onChange={onChange}
                            allowClear={false}
                            disabledDate={disabledDate}
                            value={date}
                            format={DateFormat}
                            style={{
                                border: "1px solid #415e81", background: "#022D5A",
                                borderRadius: "4px", width: 200, marginLeft: 20, marginTop: "12px"
                            }}
                        />

                        <Typography sx={{ fontSize: 16, mr: 2, ml: 4, mt: 2 }}>Selected Date: {date_key}</Typography>
                    </Box>
                    {sections?
                    <>
                    <div className="header">Participant Wise Position Changes</div>

                    <div className="grid-container">
                        {sections.map((section, index) => (
                            <Card
                                key={index}
                                className="section-card"
                                style={{ backgroundColor: section.color }}
                            >
                                <div className="section-title">{section.title}</div>

                                <div style={{ width: "50%" }}>
                                    <Table
                                        columns={futuresColumns}
                                        dataSource={section.data}
                                        pagination={false}
                                        bordered
                                        size="small"
                                        className="custom-table"
                                    />
                                </div>


                                <Table
                                    columns={optionsColumns}
                                    dataSource={section.options}
                                    pagination={false}
                                    bordered
                                    size="small"
                                    className="custom-table"
                                    title={() => <div style={{ fontWeight: "bold", textAlign: "center" }}>Options</div>} // ✅ Centered & Bold
                                />
                            </Card>
                        ))}
                    </div>
                    </>:
                    <>
                    <div style={{ textAlign: "center", width: "100%" }}>
                    <Typography sx={{ fontSize: 16, marginTop: "10%" }}>Data not present for selected date</Typography>
                    </div>
                    </>
                    }
                </div>
                :
                <div style={{ textAlign: "center", width: "100%" }}><CircularProgress sx={{ marginTop: "10%" }} />
                </div>
            }


            <Snackbar onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION_SHORT} >
                <Alert severity={severity} sx={{ marginTop: "48px", width: { mobile: '80%', tablet: "70%", laptop: "40%" } }}>
                    {msg}
                </Alert>
            </Snackbar>
        </>
    )
}