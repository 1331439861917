import Container from "./layouts/container";
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider,CssBaseline } from '@mui/material';
import theme from './themes'
import {QueryClientProvider,QueryClient} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools'
import { useEffect } from "react";
import { requestNotificationPermission, messaging } from "./firebase";
import { onMessage } from "firebase/messaging";
import { sendFcmTokenToServer } from "./fcmService";

// setup the react query clients
const queryClient=new QueryClient();


function App() {
  useEffect(() => {
    // Ask for notification permission when the app loads
    // requestNotificationPermission();

    // Handle foreground notifications
    // const unsubscribe = onMessage(messaging, (payload) => {
    //   console.log(" FCM APP Foreground Notification Received:", payload);

    //   // Show notification using alert (replace with a better UI if needed)
    //   // alert(`New Notification: ${payload.notification?.title} - ${payload.notification?.body}`);
    // });

    // return () => unsubscribe(); // Cleanup listener on unmount
  }, []);
  
  console.log("theme object=",theme);
  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
            <Container/>
        </ThemeProvider>
      </StyledEngineProvider>
    
      {/* TODO: remove the devtools in development */}
      {/* <ReactQueryDevtools initialIsOpen={false}/> */}
    </QueryClientProvider>
  );
}

export default App;
