import axios from "axios";
import { URL } from "./common/api/urls";
const BASE_URL = process.env.REACT_APP_BASE_URL;

//will be used to save FCM token for the current user to the backend server
export const sendFcmTokenToServer = async (fcmToken,userToken) => {
  try {
   
   let data={fcm_token:fcmToken}
    await axios.post(`${BASE_URL}${URL.SAVE_FCM_TOKEN}`, data, {
        headers: {
          Authorization: `${userToken}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      console.log("FCM Token sent to backend successfully");
      return true;
  } catch (error) {
    console.error("Error sending FCM Token to backend:", error);
    return false;
  }
};
